import * as React from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

import { LinkTag, MetaTag } from 'next-seo/lib/types'
import { siteConfig } from '../../utils/siteConfig'

interface IHTMLHeadProps {
  description?: string
  meta?: ReadonlyArray<MetaTag>
  title?: string
  socialshare?: string
  addDefaultMeta?: boolean
  pageTitle?: string
  noIndex?: boolean
}

const FALLBACK_URL =
  typeof window !== 'undefined' ? window?.location?.origin || `` : ``

/**
 * Adds metadata, classNames, scripts and others to head & body via Helmet.
 *
 * @param {string}                    description               The meta description.
 * @param {ReadonlyArray<MetaTag>}    meta                      Custom meta-tag object (default: []).
 * @param {string}                    title                     The site title.
 * @param pageTitle
 * @param {string}                    socialshare               URl to social share image (default: https://solanamobile.com/solanamobile_socialshare.png).
 * @param {boolean}                   addDefaultMeta            If the default meta-tags should be added if "meta" present (default: true).
 * @param {boolean}                   noIndex                   If a noindex,nofollow robots meta tag should be added.
 * @returns {JSX.Element}
 * @constructor
 */
const HTMLHead = ({
  description = ``,
  meta = [],
  title = ``,
  pageTitle = ``,
  socialshare = ``,
  addDefaultMeta = true,
  noIndex = false,
}: IHTMLHeadProps) => {
  const { asPath } = useRouter()

  const metaTitle = title || siteConfig.siteMetadata.title
  const metaDescription = description || siteConfig.siteMetadata.description
  const metaSocialShare = socialshare || siteConfig.siteMetadata.socialshare
  const currentPageTitle = pageTitle || siteConfig.siteMetadata.title

  const strippedPath = asPath.split('?')[0].split('#')[0]

  const canonicalUrl = `${siteConfig.siteUrl || FALLBACK_URL}${strippedPath}`

  const twitterSite = `https://twitter.com/${siteConfig.siteMetadata.author.replace(
    /^@/,
    ``
  )}/`

  const additionalLinkTags: ReadonlyArray<LinkTag> = [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'alternate',
      hrefLang: 'x-default',
      href: canonicalUrl,
    },
  ]

  return (
    <>
      <NextSeo
        title={addDefaultMeta ? title : undefined}
        titleTemplate={`%s | ${currentPageTitle}`}
        noindex={noIndex}
        nofollow={noIndex}
        canonical={addDefaultMeta ? canonicalUrl : undefined}
        openGraph={
          addDefaultMeta
            ? {
                type: 'website',
                url: canonicalUrl,
                title: metaTitle,
                description: metaDescription,
                images: [
                  {
                    url: metaSocialShare,
                  },
                ],
              }
            : undefined
        }
        twitter={
          addDefaultMeta
            ? {
                handle: siteConfig.siteMetadata.author,
                site: twitterSite,
                cardType: 'summary',
              }
            : undefined
        }
        additionalLinkTags={additionalLinkTags}
        additionalMetaTags={meta}
      />
    </>
  )
}

export default HTMLHead
