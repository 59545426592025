import { APP_URL } from './constants'

export const siteConfig = {
  siteMetadata: {
    title: "Solana Seeker | The Definitive Web3 Mobile Device",
    description: "Preorder now to access your favorite Solana dApps: Payments, DeFi, DePIN, NFTs, AI, Gaming, and exclusives only on Solana Mobile.",
    socialshare: `${APP_URL}/metatags-banner.jpg`,
    author: `@solanamobile`,
  },
  siteUrl: APP_URL,
}
