//@ts-ignore
export const ExpandMoreIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_291_681"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_291_681)">
        <path
          d="M14 17.938L7 10.938L8.63333 9.30469L14 14.6714L19.3667 9.30469L21 10.938L14 17.938Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
