import { type } from 'os'
import { useRef } from 'react'

interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  extraClass?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  inverted?: boolean
}

export const WhiteButton = ({
  children,
  extraClass,
  onClick,
  disabled,
  type,
  inverted,
}: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const handleClick = () => {
    buttonRef.current?.blur()
    onClick && onClick()
  }
  const defaultClasses = inverted
    ? 'border-white border-[1px] text-white bg-[#090B0F]'
    : 'text-black bg-white'
  const disabledOrHoverClasses = disabled
    ? inverted
      ? '!bg-[#090B0F] !text-[#3A4047] !border-[#3A4047]'
      : '!bg-[#3A4047] !text-[#818C99]'
    : inverted
    ? ' hover:bg-[#191E23] focus:text-black focus:bg-white'
    : 'hover:bg-[#C4CBD4] focus:bg-[#818C99]'
  return (
    <button
      type={type || 'button'}
      ref={buttonRef}
      disabled={disabled}
      onClick={() => handleClick()}
      className={`
        ${disabledOrHoverClasses}
        ${defaultClasses} px-10 py-3 rounded-3xl ${extraClass}
      `}
    >
      {children}
    </button>
  )
}
