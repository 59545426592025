import { SolanaMobileWalletAdapterWalletName } from '@solana-mobile/wallet-adapter-mobile'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import Image from 'next/legacy/image'
import React, {
  PropsWithChildren,
  useEffect,
  RefObject,
  useRef,
  useState,
} from 'react'
import { shortenAddress } from '../../utils/strings'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { isMobileBrowser } from '../../lib/utils'
import {
  useVerifyWallet,
  VerifiedStatuses,
} from '../../context/useVerifyWallet'
import {
  WalletConnectionError,
  WalletNotReadyError,
} from '@solana/wallet-adapter-base'
import { useInfoBox } from '../../context/useInfoBox'
import { WhiteButton } from '../WhiteButton'

interface PropperOverlayProps {
  isOptionsOpen: boolean
  currentAnchor: HTMLDivElement | null
  isMobile?: boolean
  setIsOptionsOpen: (isOpen: boolean) => void
  setVisible: (isVisible: boolean) => void
  handleDisconnect: () => void
}

interface BaseButtonProps {
  onClick?: () => void
  connected?: boolean
  extraClass?: string
  extraClassConnected?: string
  colorClass?: string
  icon?: string
  ref?: RefObject<HTMLDivElement>
  isMobile?: boolean
  inverted?: boolean
}

interface ConnectProps extends BaseButtonProps {
  onConnect?: () => void
  onDisconnect?: () => void
  toBuy?: boolean
  showAsLink?: string | boolean
  onHomeScreen?: boolean
  onlyCaret?: boolean
}

export const Connect = (props: ConnectProps) => {
  const {
    publicKey,
    connect,
    connected,
    disconnect,
    wallet,
    connecting,
    select: selectAdapter,
  } = useWallet()
  const { setVisible } = useWalletModal()
  const { enqueueInfo } = useInfoBox()
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const adapterRef = useRef(wallet?.adapter?.name)
  const { setVerifiedStatus } = useVerifyWallet()

  const handleConnect = async () => {
    try {
      wallet?.adapter?.name === SolanaMobileWalletAdapterWalletName ||
      !!wallet?.adapter
        ? await connect()
        : setVisible(true)
    } catch (error) {
      handleConnectErr(error as Error)
    }
  }

  const handleConnectErr = async (error: Error) => {
    if (
      error instanceof WalletNotReadyError ||
      error instanceof WalletConnectionError
    ) {
      if (!(error as Error).message) {
        selectAdapter(null)
      }
    } else {
      enqueueInfo(
        "Your wallet wasn't connected. Please try connecting it again.",
        { variant: 'error' }
      )
      const { captureException } = await import('@sentry/nextjs')
      captureException(error)
    }
  }

  const handleDisconnect = () => {
    disconnect()
    props.onDisconnect && props.onDisconnect()
    setVerifiedStatus(VerifiedStatuses.Init)
  }

  useEffect(() => {
    if (!wallet || connecting) return
    if (
      !connected &&
      wallet?.adapter?.name !== SolanaMobileWalletAdapterWalletName &&
      (!isMobileBrowser() || adapterRef.current !== wallet?.adapter?.name)
    ) {
      try {
        connect().catch((error) => {
          handleConnectErr(error as Error)
        })
      } catch (error) {
        handleConnectErr(error as Error)
      }
      return
    }
  }, [wallet?.adapter, connected, connecting, isMobileBrowser])

  if (props.showAsLink) {
    return !connected ? (
      <button
        onClick={() => {
          if (props.onClick) props.onClick()
          handleConnect()
        }}
        className={`text-[#14F195] w-full ${
          props.onHomeScreen && `text-center lg:text-start`
        }
          ${props.showAsLink == true && 'lg:text-start'}
          ${props.showAsLink == 'Connect wallet' && 'text-start'}`}
      >
        {props.showAsLink !== true
          ? props.showAsLink
          : 'Add your Saga Pass or NFT discount'}
      </button>
    ) : (
      <></>
    )
  }

  return !connected ? (
    <div>
      <BaseButton
        connected={connected}
        onClick={() => {
          if (props.onClick) props.onClick()
          handleConnect()
          setIsOptionsOpen(false)
        }}
        colorClass={props.colorClass}
        icon={props.icon}
        isMobile={props.isMobile}
        inverted={props.inverted}
        extraClass={props.extraClass}
      >
        Connect
      </BaseButton>
    </div>
  ) : (
    <div className="relative !text-white" ref={anchorRef}>
      {props.onlyCaret ? (
        <>
          <div className="flex">
            <div className="flex flex-row gap-4 xs:gap-6 items-center">
              <Image
                src="/bolt.svg"
                alt="lightning-icon"
                width={20}
                height={20}
              />
              <span>{shortenAddress(publicKey!.toBase58())}</span>
            </div>
            <div onClick={() => setIsOptionsOpen(true)}>
              <KeyboardArrowDownIcon />
            </div>
          </div>
          <PopperOverlay
            isOptionsOpen={isOptionsOpen}
            currentAnchor={anchorRef.current}
            isMobile={props.isMobile}
            setIsOptionsOpen={setIsOptionsOpen}
            setVisible={setVisible}
            handleDisconnect={handleDisconnect}
          />
        </>
      ) : (
        <>
          <div className="flex flex-col xs:flex-row gap-2 xs:gap-8 ">
            <BaseButton
              connected={connected}
              onClick={() => setIsOptionsOpen(true)}
              isMobile={props.isMobile}
              extraClass={props.extraClassConnected}
            >
              Connected wallet
            </BaseButton>
            <div className="flex flex-row gap-4 xs:gap-6 items-center">
              <Image
                src="/bolt.svg"
                alt="lightning-icon"
                width={20}
                height={20}
              />
              <span>{shortenAddress(publicKey!.toBase58())}</span>
            </div>
          </div>
          <PopperOverlay
            isOptionsOpen={isOptionsOpen}
            currentAnchor={anchorRef.current}
            isMobile={props.isMobile}
            setIsOptionsOpen={setIsOptionsOpen}
            setVisible={setVisible}
            handleDisconnect={handleDisconnect}
          />
        </>
      )}
    </div>
  )
}

const PopperOverlay = ({
  isOptionsOpen,
  currentAnchor,
  isMobile,
  setIsOptionsOpen,
  setVisible,
  handleDisconnect,
}: PropperOverlayProps) => {
  return (
    <Popper
      sx={{
        zIndex: 1,
        minWidth: '200px',
        marginTop: '10px',
      }}
      open={isOptionsOpen}
      role={undefined}
      anchorEl={currentAnchor}
      transition
      disablePortal
      placement={isMobile ? 'bottom-end' : 'bottom-start'}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setIsOptionsOpen(false)}>
              <MenuList
                id="split-button-menu"
                autoFocusItem
                className="bg-[#191E23]"
              >
                <MenuItem
                  className="h-[48px] !text-white !hover:bg-[#3a4047]"
                  onClick={() => setVisible(true)}
                >
                  Change wallet
                </MenuItem>
                <MenuItem
                  className="h-[48px] !text-white !hover:bg-[#3a4047]"
                  onClick={handleDisconnect}
                >
                  Disconnect wallet
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

const BaseButton = ({
  children,
  connected,
  onClick,
  extraClass = 'block',
  colorClass = 'text-white',
  isMobile,
  inverted,
}: PropsWithChildren<BaseButtonProps>) => {
  return (
    <WhiteButton
      onClick={onClick}
      type="button"
      extraClass={extraClass}
      inverted={inverted}
    >
      <span className="text-base font-medium">{children}</span>
      {connected && <KeyboardArrowDownIcon />}
    </WhiteButton>
  )
}
